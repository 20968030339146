import service from '@/utils/request'; // @Tags EquityGoods
// @Summary 创建EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "创建EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityGoods/createEquityGoods [post]

export var createEquityGoods = function createEquityGoods(data) {
  return service({
    url: "/equityGoods/createEquityGoods",
    method: 'post',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 删除EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "删除EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoods/deleteEquityGoods [delete]

export var deleteEquityGoods = function deleteEquityGoods(data) {
  return service({
    url: "/equityGoods/deleteEquityGoods",
    method: 'delete',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 删除EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoods/deleteEquityGoods [delete]

export var deleteEquityGoodsByIds = function deleteEquityGoodsByIds(data) {
  return service({
    url: "/equityGoods/deleteEquityGoodsByIds",
    method: 'delete',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 更新EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "更新EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityGoods/updateEquityGoods [put]

export var updateEquityGoods = function updateEquityGoods(data) {
  return service({
    url: "/equityGoods/updateEquityGoods",
    method: 'put',
    data: data
  });
}; // @Tags EquityGoods
// @Summary 用id查询EquityGoods
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoods true "用id查询EquityGoods"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityGoods/findEquityGoods [get]

export var findEquityGoods = function findEquityGoods(params) {
  return service({
    url: "/equityGoods/findEquityGoods",
    method: 'get',
    params: params
  });
}; // @Tags EquityGoods
// @Summary 分页获取EquityGoods列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityGoods列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityGoods/getEquityGoodsList [get]

export var getEquityGoodsList = function getEquityGoodsList(params) {
  return service({
    url: "/equityGoods/getEquityGoodsList",
    method: 'get',
    params: params
  });
};